import { navigate } from "gatsby"
import React from "react"
import { ToastContainer } from "react-toastify"
import { FACEBOOK_BLACK } from "../../assets/colors/Colors"
import CobraHeader from "../../client-ui/shared/CobraHeader"
import { AuthLogo, TitleRspAlt, SubtitleRsp, SubtitleIIRsp, AuthLogoLgCentered } from "../../locust/components/Auth"
import { LayoutFS } from "../../locust/composables/Layout"
import X from "../../locust/composables/X"
import SpacerV from "../../locust/sections/SpacerV"
import { getShoppingCart } from "../../service/shopping-cart"

enum Constants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
}

const Content = {
    signup : {
        title : `It's Who You Know`,
        subtitle : 'Too many attempts',
        fineprint  : 'By using this website, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        hasAccount : `Don't have an Account?`,
        signUpBtn : 'Reset'
    }
}

export default()=>{
    return (<>
        
        <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                

                <SpacerV h={Constants.GUTTER}/>
                <AuthLogoLgCentered/>
                <SpacerV h={Constants.GUTTER}/>
                
                <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                <SubtitleRsp c={FACEBOOK_BLACK}>{Content.signup.subtitle}</SubtitleRsp>
                    
                <SpacerV h={Constants.GUTTER}/>

                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >
                            <div style={{ textAlign : 'center'}}>
                            You have exceeded the number of attempts allowed.<br/>
                            Please try again later.
                        </div>
                        </SubtitleIIRsp>
                   
                
            </LayoutFS>
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
    </>)
}